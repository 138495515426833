import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArticleList = ({ articles }) => {
  return (
    <div>
      {articles.map(({ node }) => (
        <div key={node.id} className="section">
          <div className="columns is-vcentered">
            <div className="column">
              <figure className="image">
                <img
                  alt={node.frontmatter.title}
                  src={node.frontmatter.cover}
                />
              </figure>
            </div>
            <div className="column">
              <Link to={node.fields.slug}>
                <h2 className="title is-size-4">{node.frontmatter.title}</h2>
              </Link>
              <div className="subtitle is-size-6">{node.frontmatter.date}</div>
              <div className="content">{node.frontmatter.excerpt}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const ArticlesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Artigos" />
      <div className="section">
        <div className="container">
          <ArticleList articles={data.allMarkdownRemark.edges} />
        </div>
      </div>
    </Layout>
  )
}

export default ArticlesPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { contentType: { eq: "article" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            cover
            slug
            date(formatString: "DD/MM/YYYY")
            contentType
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
